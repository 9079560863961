import '../polyfills';
import '../wdyr';
import '../common/i18n';
import 'swiper/css';
import 'swiper/css/pagination';
import 'photoswipe/dist/photoswipe.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { useTranslation } from 'react-i18next';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { ApolloProvider } from '@apollo/client';
import AppBaseProvider from '@components/AppBaseProvider';
import AuthSuspense from '@components/AuthSuspense';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import Head from 'next/head';
import { LocalizationProvider } from '@mui/x-date-pickers';
import '@mui/lab';
import * as Sentry from '@sentry/browser';
import NextApp from 'next/app';
import {
  Provider as ReduxProvider,
  useSelector,
} from 'react-redux';
import Router from 'next/router';
import {
  SessionProvider,
  useSession,
} from 'next-auth/react';
import { SnackbarProvider } from 'notistack';
import SplashScreen from '@components/SplashScreen';
import { ThemeProvider } from '@mui/material/styles';
import { createEmotionCache } from '@utils/create-emotion-cache';
import nProgress from 'nprogress';
import {
  useEffect,
  useState,
} from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
import getConfig from 'next/config';
import { selectCurrentTenant } from '@slices/tenant';
import {
  init,
  push,
} from '@lib/analytics';
import { store } from '@store';
import {
  createTheme,
  DEFAULT_FONT,
} from '../common/theme';
import { useApollo } from '../common/store/graphql';

Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

const clientSideEmotionCache = createEmotionCache();

const { publicRuntimeConfig } = getConfig();
LicenseInfo.setLicenseKey(publicRuntimeConfig.MUI_LICENSE);

function App(props) {
  const {
    emotionCache = clientSideEmotionCache,
    pageProps,
  } = props;
  const apolloClient = useApollo(pageProps);

  return (
    <CacheProvider value={emotionCache}>
      <ReduxProvider store={store}>
        <SessionProvider session={pageProps.session}>
          <ApolloProvider client={apolloClient}>
            <AppContent {...props} />
          </ApolloProvider>
        </SessionProvider>
      </ReduxProvider>
    </CacheProvider>
  );
}

function AppContent(props) {
  const {
    Component,
    pageProps,
    baseProps,
  } = props;
  const { i18n } = useTranslation();
  const { data: session, status } = useSession();
  const tenant = useSelector(selectCurrentTenant);
  const [isAnalyticsInit, setAnalyticsInit] = useState(false);
  const pageTitle = `${tenant?.attributes.name ?? ''} Veranstaltungsplaner`.trim();
  const getLayout = Component.getLayout ?? ((page) => page);
  const favicon = tenant?.attributes.favicons?.data?.[0]?.attributes.url ?? '/favicon-32.png';

  useEffect(() => {
    if (status === 'loading') return;

    if (status === 'unauthenticated') {
      window.__user = null;
    }

    if (status === 'authenticated') {
      window.__user = session.user.username;
    }

    if (!isAnalyticsInit && publicRuntimeConfig.MATOMO_URL && publicRuntimeConfig.MATOMO_SITE_ID) {
      init({
        url: publicRuntimeConfig.MATOMO_URL,
        siteId: publicRuntimeConfig.MATOMO_SITE_ID,
        disableCookies: true,
        onInitialization: () => {
          if (window.__user) {
            push(['setUserId', window.__user]);
          }
        },
        onRouteChangeStart: () => {
          if (window.__user) {
            push(['setUserId', window.__user]);
          }
        },
      });
      setAnalyticsInit(true);
    }
  }, [
    status,
    session,
    isAnalyticsInit,
    setAnalyticsInit,
  ]);

  useEffect(() => {
    Sentry.setUser(session?.user ? {
      id: session.user.id,
      username: session.user.username,
    } : null);
  }, [status, session]);

  return (
    <>
      <Head>
        <title>
          {pageTitle}
        </title>
        <meta
          content="initial-scale=1, width=device-width"
          name="viewport"
        />
        <link href={favicon} rel="icon" type="image/png" />
      </Head>
      <ThemeProvider
        theme={createTheme({
          direction: 'ltr',
          responsiveFontSizes: true,
          mode: 'light',
          fontFamily: DEFAULT_FONT.style.fontFamily,
          brandThemeLight: tenant?.attributes.brandThemeLight,
          brandThemeDark: tenant?.attributes.brandThemeDark,
        })}
      >
        <SnackbarProvider
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'top',
          }}
          maxSnack={3}
        >
          <CssBaseline />
          <LocalizationProvider
            adapterLocale={i18n.language}
            dateAdapter={AdapterLuxon}
          >
            <AuthSuspense fallback={<SplashScreen />}>
              <AppBaseProvider
                {...baseProps}
                fallback={<SplashScreen />}
              >
                {getLayout(
                  <Component {...pageProps} />,
                )}
              </AppBaseProvider>
            </AuthSuspense>
          </LocalizationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
}

App.getInitialProps = async (appContext) => {
  const appProps = await NextApp.getInitialProps(appContext);

  return {
    ...appProps,
    baseProps: {
      body: {
        className: DEFAULT_FONT.className,
      },
      host: typeof window === 'undefined'
        ? getHostname(appContext.ctx.req.headers.host)
        : window.location.hostname,
    },
  };
};

function getHostname(host) {
  if (host.includes(':')) {
    return host.substring(0, host.indexOf(':'));
  }

  return host;
}

export default App;
