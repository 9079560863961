import accountModule from '@modules/account/module';
import adminModule from '@modules/admin/module';
import asanaModule from '@modules/asana/module';
import eventModule from '@modules/event/module';
import eventsModule from '@modules/events/module';
import hubspotModule from '@modules/hubspot/module';
import schedulesModule from '@modules/schedules/module';

export const gtmConfig = {
  containerId: process.env.NEXT_PUBLIC_GTM_CONTAINER_ID,
};

export const DEFAULT_LOGO = {
  light: '/images/default-logo-light.svg',
  dark: '/images/default-logo-dark.svg',
};

export const languages = [
  {
    lang: 'de',
    label: 'Deutsch',
    icon: '/static/icons/de_flag.svg',
  },
  {
    lang: 'en',
    label: 'English',
    icon: '/static/icons/uk_flag.svg',
  },
];

export const routes = {
  default: '/',
  auth: {
    login: '/auth/login',
  },
};

export const modules = [
  eventModule,
  eventsModule,
  schedulesModule,
  asanaModule,
  adminModule,
  accountModule,
  hubspotModule,
];

export const USER_ROLES = {
  customer: ['customer_readonly', 'customer_editor'],
  staff: ['mitarbeiter_nur_dateien', 'staff_readonly'],
  eventManager: ['staff_event_manager'],
};
