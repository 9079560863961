import { useCurrentEvent, useCurrentEventDay } from '@modules/event/hooks';
import { useDispatch } from 'react-redux';
import { setTitle } from '@slices/navbar-title';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export function useEventNavbarTitle(moduleTitle) {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [event] = useCurrentEvent();
  const eventDay = useCurrentEventDay();
  let title = null;
  let subtitle = null;

  if (event && eventDay) {
    const { customer, customerContact } = event.attributes;
    const locationName = event.attributes.location.data.attributes[`name_${i18n.language}`];
    const { occasion } = eventDay.attributes;
    const date = DateTime.fromISO(eventDay.attributes.date)
      .setLocale(i18n.language)
      .toLocaleString(DateTime.DATE_SHORT);
    const rooms = eventDay.attributes.rooms.data
      .map((r) => r.attributes.room.data?.attributes[`name_${i18n.language}`] || '<Raum fehlt>')
      .join(', ');

    title = `${customer ?? ''} - ${date} - ${occasion ?? ''}`;
    subtitle = `${locationName} - ${rooms} - ASP: ${customerContact ?? '-'}`;
  }

  useEffect(() => {
    dispatch(setTitle({ title, subtitle }));
  }, [dispatch, title, subtitle]);

  return `${title} | ${moduleTitle}`;
}
