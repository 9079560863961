import Router from 'next/router';

/*
  if (status === 'authenticated') {
    trackEvent(['setUserId', session.user.username]);
  }

  if (status === 'unauthenticated') {
    trackEvent(['resetUserId']);
  }
 */

const isExcludedUrl = (url, patterns) => {
  let excluded = false;
  patterns.forEach((pattern) => {
    if (pattern.exec(url) !== null) {
      excluded = true;
    }
  });
  return excluded;
};

// to push custom events
export function push(args) {
  if (!window._paq) {
    window._paq = [];
  }
  window._paq.push(args);
}

export function resetUserTracking() {
  // https://developer.matomo.org/guides/tracking-javascript-guide
  push(['resetUserId']);
  push(['appendToTrackingUrl', 'new_visit=1']);
  push(['trackPageView']);
  push(['appendToTrackingUrl', '']);
}

const startsWith = (str, needle) => str.substring(0, needle.length) === needle;
const trustedPolicyHooks = {
  createScript: (s) => s,
  createScriptURL: (s) => s,
};

// initialize the tracker
export function init({
  url,
  siteId,
  jsTrackerFile = 'matomo.js',
  phpTrackerFile = 'matomo.php',
  excludeUrlsPatterns = [],
  disableCookies = false,
  onRouteChangeStart = undefined,
  onRouteChangeComplete = undefined,
  onInitialization = undefined,
  onScriptLoadingError = undefined,
  nonce,
  trustedPolicyName = 'matomo-next',
  logExcludedTracks = false,
}) {
  window._paq = window._paq !== null ? window._paq : [];
  if (!url) {
    console.warn('Matomo disabled, please provide matomo url');
    return;
  }

  const sanitizer = window.trustedTypes?.createPolicy(trustedPolicyName, trustedPolicyHooks)
    ?? trustedPolicyHooks;

  let previousPath = '';
  // order is important -_- so campaign are detected
  const excludedUrl = typeof window !== 'undefined'
    && isExcludedUrl(window.location.pathname, excludeUrlsPatterns);

  if (onInitialization) onInitialization();

  if (excludedUrl) {
    if (typeof window !== 'undefined' && logExcludedTracks) {
      console.log(`matomo: exclude track ${window.location.pathname}`);
    }
  } else {
    push(['trackPageView']);
  }

  if (disableCookies) {
    push(['disableCookies']);
  }

  push(['enableLinkTracking']);
  push(['setTrackerUrl', `${url}/${phpTrackerFile}`]);
  push(['setSiteId', siteId]);

  /**
   * for initial loading we use the location.pathname
   * as the first url visited.
   * Once user navigate across the site,
   * we rely on Router.pathname
   */

  const scriptElement = document.createElement('script');
  const refElement = document.getElementsByTagName('script')[0];
  if (nonce) {
    scriptElement.setAttribute('nonce', nonce);
  }
  scriptElement.type = 'text/javascript';
  scriptElement.async = true;
  scriptElement.defer = true;
  const fullUrl = `${url}/${jsTrackerFile}`;
  scriptElement.src = sanitizer.createScriptURL?.(fullUrl) ?? fullUrl;
  if (onScriptLoadingError) {
    scriptElement.onerror = () => {
      onScriptLoadingError();
    };
  }
  if (refElement.parentNode) {
    refElement.parentNode.insertBefore(scriptElement, refElement);
  }
  previousPath = window.location.pathname;

  const defaultOnRouteChangeStart = (path) => {
    if (isExcludedUrl(path, excludeUrlsPatterns)) return;

    // We use only the part of the url without the querystring to ensure piwik is happy
    // It seems that piwik doesn't track well page with querystring
    let [pathname] = path.split('?');
    pathname = pathname.replace(/#.*/, '');

    if (previousPath) {
      push(['setReferrerUrl', `${previousPath}`]);
    }
    push(['setCustomUrl', pathname]);
    push(['deleteCustomVariables', 'page']);
    previousPath = pathname;

    if (onRouteChangeStart) onRouteChangeStart(path);
  };

  Router.events.on('routeChangeStart', defaultOnRouteChangeStart);

  const defaultOnRouteChangeComplete = (path) => {
    if (isExcludedUrl(path, excludeUrlsPatterns)) {
      return;
    }

    // In order to ensure that the page title had been updated,
    // we delayed pushing the tracking to the next tick.
    setTimeout(() => {
      const { q } = Router.query;
      push(['setDocumentTitle', document.title]);
      if (startsWith(path, '/recherche') || startsWith(path, '/search')) {
        push(['trackSiteSearch', q ?? '']);
      } else {
        push(['trackPageView']);
      }
    }, 0);

    if (onRouteChangeComplete) onRouteChangeComplete(path);
  };

  Router.events.on('routeChangeComplete', defaultOnRouteChangeComplete);
}

export default init;
